<template>
    <ErrorRenderer v-if="capturedError" />

    <DatePicker 
        v-else
        ref="datepickerRef"
        :dark="darkMode"
        :teleport-center ="isCenter"
        :locale="uiLocale" :format="computedFormat" 
        :textInput="openOnIconClick ? textInputOptions : true"
        :hideInputIcon="!(openOnIconClick||showInputIcon)"
        :modelValue="computedModelValue"  
        @update:modelValue="setDate" 
        :clearable="clearable" 
        autoApply
        :enableTimePicker="timepickerOnly?undefined:enableTimepicker" 
        :enableSeconds="enableSeconds"
        menuClassName="shadow"
        :teleport="teleport ?? (isGridEditor ? true : undefined)"
        :time-picker="timepickerOnly"
        :time-picker-inline="timepickerOnly?undefined:enableTimepicker"
        :config="{
            keepActionRow: true,
            closeOnAutoApply: enableTimepicker ? false : true
        }"
        @open="() => onOpen()"
        @closed="() => closed()"
        :class="containerClass"
        :range="range"
        :utc="utc"
        v-bind="{ ...$attrs, class: null, style: null }"
        :id="id"
        >
        <template #input-icon>
            <span v-if="openOnIconClick" class="input-group-text border-0 ps-2" style="background-color:inherit" @click.passive="() => openMenu(true)">
                <i v-if="!timepickerOnly" class="bi bi-calendar fa-sm"></i>
                <i v-if="timepickerOnly" class="bi bi-clock"></i>
            </span>
            <span v-else class="input-group-text border-0 ps-2" style="background-color:inherit" @click.passive="tryToFocusInput">
                <i v-if="!timepickerOnly" class="bi bi-calendar fa-sm"></i>
                <i v-if="timepickerOnly" class="bi bi-clock"></i>
            </span>
        </template>
        <template #dp-input="{ value, onInput, onEnter, onTab, onClear, onFocus, onBlur }">
            <OMediaQueryProvider>
                 <OMatchMedia v-slot="{ isMobileOrTablet }">
                    <DatePickerInputMask v-if="autoSuggestion && !$slots.default && !range"
                        ref="inputMaskRef"
                        v-bind="$attrs" :key="computedFormat"
                        :value="value"
                        :class="[{'dp__input_icon_pad': openOnIconClick||showInputIcon}]"
                        :style="inputStyle"
                        :format="computedFormat"
                        :onInput="onInput"
                        :onEnter="onEnter"
                        :onTab="onTab"
                        :onFocus="onFocus"
                        :blurCallback="onBlur"
                        :inputmode="isMobileOrTablet ? 'none' : 'text'"
                        :onClear="onClear"/>
                    <slot v-else :value="value" :onInput="onInput" :onEnter="onEnter" :onTab="onTab" :onClear="onClear" :onFocus="onFocus" :onBlur="onBlur">
                        <input type="text" :inputmode="isMobileOrTablet ? 'none' : 'text'" :value="value"
                            @input="onInput" @keydown.enter="onEnter" @keydown.tab="onTab" @focus="onFocus" @blur="onBlur" 
                            :class="[{'dp__input_icon_pad': openOnIconClick||showInputIcon}]" :style="inputStyle" v-bind="$attrs" />
                    </slot>
                </OMatchMedia>
            </OMediaQueryProvider>
        </template>
        <template #action-buttons>
            <button v-if="clearable || clearableFromPicker" class="btn btn-sm btn-outline-primary py-0 text-nowrap" @click="clearValue">
                {{$t('Clear')}}
            </button>
            <button v-if="enableTimepicker || timepickerOnly" class="btn btn-sm btn-primary py-0 ms-2 text-nowrap"
                @click="selectValue">
                {{$t('Ok')}}
            </button>
        </template>
    </DatePicker>
</template>

<script lang="ts">
// DO NOT ADD COMMENTS OR OTHER NODES ON TEMPALTE ROOT
// This is an input editor therefore it must have a single root noode
// v-if-else chains are ok as long as only one node is rendered

import type { DataColumn } from 'o365-datagrid';

export interface IDatePickerProps {
    modelValue?: any;
    date?: boolean,
    column?: DataColumn,
    format?: string | ((pDate: Date) => string),
    timepicker?: boolean,
    outputTimeString?: boolean,
    timepickerOnly?: boolean,
    filterObject?: any,
    callback?: (newValue: string|Date|null) => void,
    clearable?: boolean,
    clearableFromPicker?: boolean,
    openOnIconClick?: boolean
    inputClassName?: any, /* DEPRECATED */
    inputStyle?: any, /* DEPRECATED */
    autoSuggestion?: boolean,
    showInputIcon?: boolean,
    teleport?: string|boolean|null,
    containerClass?: string,
    range?: boolean,
    utc?: boolean | 'preserve',
    disableDefaultDateSetter?: boolean,
    defaultHour?: string | number,
    defaultMinutes?: string | number
};

export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
import OMediaQueryProvider from './Helpers.MediaQueryProvider.vue';
import OMatchMedia from './Helpers.MatchMedia.vue';
import DatePickerInputMask from './Inputs.DatePicker.InputMask.vue';
import DatePicker from 'vue-datepicker';
import { dateUtils as utilsDate } from 'o365-utils';
import { getUserSession } from 'o365-modules';
import { ref, computed, inject, useAttrs, onMounted, onBeforeUnmount} from 'vue';
import { useErrorCapture } from 'o365-vue-utils';

const props = withDefaults(defineProps<IDatePickerProps>(), {
    clearable: true,
    autoSuggestion: true,
    showInputIcon:false,
    teleport: true,
    defaultHour: "8",
    defaultMinutes: "0"
    // utc: raw => raw.date ? 'preserve' : undefined
});

if (props.inputClassName || props.inputStyle) {
    console.warn("inputClassName and inputStyle props should no longer be used in the DatePicker component. Please use standard class and style attributes instead.");
}

const emit = defineEmits<{
    (e: 'update:modelValue', value: string|Date|null): void
}>();
const isCenter = ref(false);
const isGridEditor = inject('is-in-grid-cell', false);
const attrs = useAttrs();
const userSession = getUserSession();
const uiLocale = userSession.uiCulture;
let vValueCleared = false;

const datepickerRef = ref<DatePicker|null>(null);

const textInputOptions = computed(() => {
    return {
        openMenu: !props.openOnIconClick
    };
});

const hideInputIcon = computed(() => {
    return props.showInputIcon?false:(props.openOnIconClick ? false : true);
});

const computedFormat = computed(() => {
    if (typeof props.format === 'function') { return props.format}
    let format = props.format;
    if (!format) {
        if (props.column && props.column.format) {
            format = props.column.format;
        } else if (props.column) {
            switch (props.column.type) {
                case 'date':
                    format = 'Short Date';
                    break;
                case 'time':
                    format = 'Short Time';
                    break;
                default:
                    format = 'General Date Short Time';
                    break;
            }
        } else if (props.timepickerOnly) {
            format = 'Short Time';
        } else {
            format = 'General Date Short Time';
        }
    }

    format = utilsDate.getFormat(format);
    // Replace 'M' with 'MM', 'd' with 'dd' and 'h' with 'hh'
    // The date picker doesn't support 1 minimum digit manual inputs
    return format.replace(/(M+|d+|h+)(?!(M|d|h)\1)/g, match => match.length % 2 === 0 ? match : match + match[0])
});
const closed = ()=> {

    defaultValue.value = null;
    vValueCleared = false;
}
const enableTimepicker = computed(() => {
    return (props.timepickerOnly || props.timepicker) && computedFormat.value !== 'Short Date';
});
const id = computed(() => {
    return crypto.randomUUID();
});

const enableSeconds = computed(() => {
    let formatString = typeof computedFormat.value === 'string' ? computedFormat.value : '';
    return (props.timepickerOnly || enableTimepicker.value) && formatString.includes('ss')
});

const openMenu = (pFocusInput) => {
    if (datepickerRef) {
        datepickerRef.value.openMenu()
    }
    if (pFocusInput) { tryToFocusInput(); }
}

const defaultValue = ref();
// Parse given model value depending on datepicker type
const computedModelValue = computed(() => {
    if (defaultValue.value != null && props.modelValue == null && !props.disableDefaultDateSetter) { return defaultValue.value; }
    // if props.modelValue == null && !props.disableDefaultSetter return default 8am time
    if (props.timepickerOnly && props.modelValue) {
        const date = props.modelValue instanceof Date ? props.modelValue : new Date(props.modelValue);
        if (props.outputTimeString) {
            if(Array.isArray(props.modelValue)){
                return
            }
            const timeParts = props.modelValue.split(':');
            return {
                hours: timeParts[0],
                minutes: timeParts[1],
                seconds: 0,
            }
        }

        if (props.utc === true) {
            return {
                hours: date.getUTCHours(),
                minutes: date.getUTCMinutes(),
                seconds: date.getUTCSeconds(),
            };
        }

        return {
            hours: date.getHours(),
            minutes: date.getMinutes(),
            seconds: date.getSeconds(),
        };
    }
    return props.modelValue;
});

const formatDoubleDigits = (pNumber: number) => String(pNumber).padStart(2, '0');

function dropTimezoneInfo(pDate: Date) {
    if (pDate instanceof Date) {
        let dateString = `${pDate.getFullYear()}-`
            + `${formatDoubleDigits(pDate.getMonth() + 1)}-`
            + `${formatDoubleDigits(pDate.getDate())}T`
            + `${formatDoubleDigits(pDate.getHours())}:`
            + `${formatDoubleDigits(pDate.getMinutes())}:`
            + `${formatDoubleDigits(pDate.getSeconds())}`;
        const ms = pDate.getMilliseconds();
        if (ms) {
            dateString += `.${ms}`
        }else{
            dateString += '.000';
        };
        return dateString;
    }
    return pDate;
}

const setDate = (pValue) => {
    defaultValue.value = null;
    if (props.timepickerOnly && pValue) {
        let newTime = props.modelValue ? new Date(props.modelValue) : new Date();
        if(props.outputTimeString){
            newTime = new Date(0);
        }
        if (props.utc === true) {
            newTime.setUTCHours(pValue.hours);
            newTime.setUTCMinutes(pValue.minutes);
            newTime.setUTCSeconds(pValue.seconds ?? 0);
        }
        else {
            newTime.setHours(pValue.hours);
            newTime.setMinutes(pValue.minutes);
            newTime.setSeconds(pValue.seconds ?? 0);
        }
        pValue = props.outputTimeString ? `${String(pValue.hours).padStart(2, '0')}:${String(pValue.minutes).padStart(2, '0')}` : newTime;
    }
    if (props.date && pValue instanceof Date) {
        pValue = dropTimezoneInfo(pValue);
    }else if(pValue && pValue instanceof Date){
        // pValue = pValue.toISOString(); // causes date rollback
    }
    emit('update:modelValue', pValue);
    if (props.callback) props.callback.call(this, pValue);
}

const darkMode = computed(() => {
    return document.documentElement.getAttribute("data-bs-theme") === 'dark';
})


const attrsWithoutClassAndStyle = computed(() => {
    const result = {};
    for (const attr in attrs) {
        if (attr !== "class" && attr !== "style") {
            result[attr] = attrs[attr];
        }
    }    
    return result;
});

const inputMaskRef = ref<DatePickerInputMask|null>(null);

function selectValue() {
    datepickerRef.value.selectDate();
    if(props.outputTimeString && props.filterObject){
        props.filterObject.apply();
    }
} 
function clearValue() {
    vValueCleared = true;
    datepickerRef.value.clearValue();
}

function tryToFocusInput() {
    if (props.autoSuggestion) {
        inputMaskRef.value?.$refs?.inputRef?.elRef?.focus();
    }
}

function activateEditor() {
    //datepickerRef.value?.closeMenu();
    datepickerRef.value?.openMenu();
    tryToFocusInput();
}

const [capturedError, ErrorRenderer] = useErrorCapture({
    consoleMessagee: `Error encountered when trying to render DatePicker`,
    errorRenderFunctionOptions: {
        uiTitleMessage: 'DatePicker Render Error'
    }
});

const onOpen = () => {
     const rect = datepickerRef.value.$el.getBoundingClientRect();
    if(rect.y < 340 && window.innerHeight - rect.y-rect.height < 340){
        isCenter.value = true
    }else{
        isCenter.value = false

    }
    if (props.date || props.format == "Short Date") return;

    if (props.modelValue == null) {
        let newDate = new Date();
        if (props.utc == 'preserve') {
            newDate = new Date(Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate(), +props.defaultHour, +props.defaultMinutes, 0, 0));
        } else {
            newDate.setHours(+props.defaultHour);
            newDate.setMinutes(+props.defaultMinutes);
            newDate.setSeconds(0);
            newDate.setMilliseconds(0);
        }
        
        defaultValue.value = newDate;
    }
}

const handleKeyPress = (event) => {
  if (event.key === 'Escape') {
    datepickerRef.value.closeMenu();
  }
};
onMounted(()=>{
    window.addEventListener('keydown', handleKeyPress);
});
onBeforeUnmount(()=>{
    window.removeEventListener('keydown', handleKeyPress);
});

defineExpose({ open, setDate, format: computedFormat, uiLocale, datepickerRef, textInputOptions, openMenu, hideInputIcon, activateEditor });
</script>
